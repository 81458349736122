import React, { useEffect, useState } from 'react';
import { Button, Chip, Grid, Typography } from '@material-ui/core';

import CustomTable from '../../common/CustomTable';
import MultiSelect from '../../common/MultiSelect';

const LOCATIONS = '/api/dt/places';
const DRIVER_DETAIL = '/api/dt/drivers/detail';

const roles = [
  { label: 'admin', value: 'admin' },
  { label: 'inactive', value: 'inactive' },
  { label: 'dispatcher', value: 'dispatcher' },
  { label: 'oncall', value: 'oncall' },
  { label: 'courier', value: 'courier' },
  { label: 'no_phone', value: 'no_phone' },
  { label: 'driver', value: 'driver' },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'driver_nm',
    key: 'driver_nm',
  },
  {
    title: 'Phone',
    dataIndex: 'ph',
    key: 'ph',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Location',
    dataIndex: 'dc_nm',
    key: 'dc_nm',
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    render: (data) => (
      <>
        {data.roles.map((role) => (
          <Chip key={role} label={role} style={{ marginLeft: 5 }} />
        ))}
      </>
    ),
  },
];

async function getDriversDetail(filters) {
  const data = await window.sch.post(DRIVER_DETAIL, filters);
  return data;
}

const INITIAL_FILTERS = {
  dc_id: [],
  roles: [],
};

const DriverList = (props) => {
  const [drivers, setDrivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });
  const [location, setLocation] = useState([]);

  const fetchLocation = async () => {
    try {
      const { place } = await window.sch.get(LOCATIONS);
      const data = place.map((loc) => ({
        value: loc.dc_id,
        label: loc.dc_nm,
      }));
      setLocation(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDriversDetail = async (filters) => {
    try {
      setIsLoading(true);
      const { drivers } = await getDriversDetail(filters);
      setDrivers(drivers);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocation();
    fetchDriversDetail(filters);
  }, [filters]);

  const handleFilterFieldChage = (event) => {
    const { name, value } = event.target;

    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({ ...INITIAL_FILTERS });
    fetchDriversDetail(INITIAL_FILTERS);
  };

  const applyFilters = () => {
    fetchDriversDetail(filters);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Driver List
      </Typography>

      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ padding: '20px' }}>
        <Grid item xs={2}>
          <MultiSelect
            name="dc_id"
            label="Location"
            options={location}
            value={filters.dc_id}
            onChange={handleFilterFieldChage}
          />
        </Grid>
        <Grid item xs={2}>
          <MultiSelect
            name="roles"
            label="Roles"
            options={roles}
            value={filters.roles}
            onChange={handleFilterFieldChage}
          />
        </Grid>

        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={applyFilters}>
            Show Records
          </Button>
        </Grid>

        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            disabled={isLoading}
            onClick={handleClearFilters}>
            Clear All
          </Button>
        </Grid>
      </Grid>

      <CustomTable
        columns={columns}
        data={drivers}
        isLoading={isLoading}
        error={{ message: 'Empty driver list' }}
        baseStyle={{
          height: '75vh',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}
        onRowClick={(id) => {
          props.history.push(
            '/admin/dtrack/driver/:driver_id'.replace(':driver_id', id)
          );
        }}
      />
    </div>
  );
};

export default DriverList;
