import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchDCs_ac } from '../../actions/dcs.ac.js';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {
    backgroundColor: 'red',
    color: '#fff',
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row',
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  padding4: {
    padding: 4,
  },
});
const TF = {
  sched_dt_str: 'Schedule Date',
  patient_nm: 'Patient Name',
  acct: 'HDMS Account Number (optional)',
  addr_1: 'Address Line 1',
  //"addr_2":"Address Line 2",
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  phone: 'PHONE',
  deliv_note: 'Instructions',
};

const DEFAULT_VALUES = {
  dc_id: -1,
  driver_id: -1,
  patient_nm: '',
  sched_dt_str: moment().format('MM-DD-YYYY'),
  cats: '',
  method_id: 1,
  deliv_note: '',
  ticket_type: 'nonbill',
  addr_1: '',
  addr_2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  is_not_encrypted: true,
};

class CreateManualTicket extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.state = {
      ticket_number: null,
      ticket: { ...DEFAULT_VALUES },
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  reset() {
    this.setState({
      ticket_number: null,
      ticket: { ...DEFAULT_VALUES },
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false,
    });
  }

  validate() {
    let invalid_fields = _.keys(this.state.ticket)
      .filter((f) => f !== 'addr_2')
      .filter((f) => {
        return this.state.ticket[f] === -1 || this.state.ticket[f] === '';
      });

    return invalid_fields.length === 0;
  }

  onChange = (field) => (e) => {
    this.setState({
      ticket: {
        ...this.state.ticket,
        [field]: e.target.value,
      },
      valid: this.validate(),
    });
  };

  onSubmit() {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });

    let dt = new moment(this.state.ticket.sched_dt_str, 'MM-DD-YYYY');
    if (!dt.isValid()) {
      this.setState({ isSubmitting: false });
      return this.props.open_snack_ac({
        variant: 'error',
        message:
          'Schedule Date Invalid - Use format MM-DD-YYYY - must be today or after',
      });
    }

    if (this.state.ticket.acct && isNaN(this.state.ticket.acct)) {
      this.setState({ isSubmitting: false });
      return this.props.open_snack_ac({
        variant: 'error',
        message: 'Account # must be a number',
      });
    }

    let today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    if (dt.isBefore(today)) {
      this.setState({ isSubmitting: false });
      return this.props.open_snack_ac({
        variant: 'error',
        message: `Schedule Date of ${dt.format(
          'MM-DD-YYYY'
        )} Invalid - Must be today or after`,
      });
    }

    let dt_api_params = {
      ...this.state.ticket,
      sched_dt_str: dt.format('YYYY-MM-DD'),
      cats: [this.state.ticket.cats],
    };
    if (dt_api_params.acct) {
      dt_api_params.acct = parseInt(
        dt_api_params.acct.trim().replace(/[^0-9]/g, ''),
        10
      );
    }

    return window.sch
      .post('/api/dt/proxy', {
        dt_api_path: 'api/dispatch.create_manual_order',
        dt_api_params,
      })
      .then((res) => {
        this.setState({
          ticket_number: res.pkg.wo_ids[0],
          isSubmitting: false,
        });
      })
      .catch(() => {
        this.setState({
          isSubmitting: false,
        });
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Could not establish DelieryTrack session - contact IT',
        });
      });
  }

  componentDidMount() {
    this.checkSituation();
  }
  componentDidUpdate() {
    this.checkSituation();
  }
  checkSituation() {
    const { dcs } = this.props;
    if (dcs.isFetching) return;
    if (dcs.error) return;
    if (dcs.pkg === null) {
      this.props.fetchDCs_ac();
    }
  }

  render() {
    const { classes, dcs } = this.props;
    if (dcs.error) {
      return (
        <FetchingStatementWrap
          title={'dcs Listing'}
          msg={'Something went wrong. Contact IT'}
        />
      );
    }

    if (!dcs.pkg || dcs.isFetching) {
      return (
        <FetchingStatementWrap
          title="DCs Listing"
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    const { pkg } = dcs;

    return (
      <main className={classes.content}>
        <div className={[classes.scrollArea, classes.padding4].join(' ')}>
          <Grid container direction="row" spacing={8}>
            {null === this.state.ticket_number && (
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel className={classes.instruction} component="label">
                    Select Type of Ticket
                  </FormLabel>
                  <FormGroup>
                    <Select
                      className={classes.selectElement}
                      input={<Input />}
                      onChange={this.onChange('ticket_type')}
                      value={this.state.ticket.ticket_type}>
                      <MenuItem value="none">Select an Action</MenuItem>
                      <MenuItem value="nonbill">Non-Billable Activity</MenuItem>
                      <MenuItem value="virtual">
                        Virtual Session (Non-Billable)
                      </MenuItem>
                      <MenuItem value="oncall">On-Call Order</MenuItem>
                      <MenuItem value="transfer">
                        Transfer/Closet Order
                      </MenuItem>
                      <MenuItem value="willcall">Will-Call (PO)</MenuItem>
                    </Select>
                  </FormGroup>
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel className={classes.lbl} component="label">
                    Select Location
                  </FormLabel>
                  <FormGroup>
                    <Select
                      className={classes.selectElement}
                      input={<Input />}
                      onChange={this.onChange('dc_id')}
                      value={this.state.ticket.dc_id}>
                      <MenuItem value={-1}>Select Location</MenuItem>
                      {pkg.dcs.map((d) => (
                        <MenuItem key={d.dc_id} value={d.dc_id}>
                          {d.dc_nm}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel className={classes.lbl} component="label">
                    Select Product
                  </FormLabel>
                  <FormGroup>
                    <Select
                      className={classes.selectElement}
                      input={<Input name="alt_action" />}
                      onChange={this.onChange('cats')}
                      value={this.state.ticket.cats}>
                      {pkg.cats.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel className={classes.lbl} component="label">
                    Select Driver to Assign and Dispatch To:
                  </FormLabel>
                  <FormGroup>
                    <Select
                      className={classes.selectElement}
                      input={<Input name="alt_action" />}
                      onChange={this.onChange('driver_id')}
                      value={this.state.ticket.driver_id}>
                      <MenuItem value={-1}>Select Driver</MenuItem>
                      {pkg.drivers
                        .filter((d) => d.dc_id === this.state.ticket.dc_id)
                        .map((d) => (
                          <MenuItem key={d.driver_id} value={d.driver_id}>
                            {d.driver_nm}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormGroup>
                </FormControl>
                <FormControl
                  fullWidth
                  component="fieldset"
                  className={classes.formControl}>
                  <FormGroup>
                    {_.keys(TF).map((k) => (
                      <TextField
                        key={k}
                        id="acct-search-entry"
                        onChange={this.onChange(k)}
                        label={TF[k]}
                        style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                        value={this.state.ticket[k]}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <FormControl
                  style={{ marginTop: 24 }}
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <Button
                    variant="contained"
                    disabled={!this.state.valid || this.state.isSubmitting}
                    color="primary"
                    size="medium"
                    onClick={this.onSubmit}>
                    Create Manual Ticket
                  </Button>
                </FormControl>
              </Grid>
            )}

            {this.state.ticket_number && (
              <Grid item xs={12} md={4}>
                <div style={{ marginBottom: 24 }}>
                  <h5>
                    Manual Ticket Created: #
                    {this.state.ticket_number - 20000000000}
                  </h5>
                </div>

                <FormControl
                  style={{ marginTop: 24 }}
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <Button
                    variant="contained"
                    disabled={!this.state.valid && !this.state.isSubmitting}
                    color="primary"
                    size="medium"
                    onClick={this.reset}>
                    Reset Form and Create Another Ticket
                  </Button>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </div>
      </main>
    );
  }
}

CreateManualTicket.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDCs_ac,
      open_snack_ac,
      close_snack_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateManualTicket))
);
