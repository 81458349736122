import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';

const WoData = (props) => {
  return (
    <Grid>
      <Typography variant="h5" color="textPrimary">
        {props.wos_doc._id}
      </Typography>
      <Typography variant="h5" color="textPrimary">
        {props.wos_doc.patient_nm + ' '}
        {props.wos_doc.acct}{' '}
      </Typography>

      <Grid container direction="column" spacing={8}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="addr_1"
            type="text"
            label="Address 1"
            defaultValue={props.wos_doc.addr_1}
            onChange={(val) => {
              props.updateWoField(val, 'addr_1');
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type="text"
            id="addr_2"
            label="Address 2"
            defaultValue={props.wos_doc.addr_2}
            onChange={(val) => {
              props.updateWoField(val, 'addr_2');
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            id="city"
            type="text"
            label="City"
            defaultValue={props.wos_doc.city}
            onChange={(val) => {
              props.updateWoField(val, 'city');
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            id="state"
            type="text"
            label="State"
            defaultValue={props.wos_doc.state}
            onChange={(val) => {
              props.updateWoField(val, 'zip');
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            id="zip"
            fullWidth
            label="Zip"
            type="text"
            defaultValue={props.wos_doc.zip}
            onChange={(val) => {
              val.zipcode = true;
              props.validatePosInt(val);
              props.updateWoField(val, 'zip');
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            fullWidth
            type="text"
            label="Shipping Instructions"
            id="shippingInstructions"
            defaultValue={props.wos_doc.deliv_note}
            onChange={(val) => {
              props.updateWoField(val, 'deliv_note');
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="textPrimary">
            {props.wos_doc.mrn}
          </Typography>
          <Typography variant="h5" color="textPrimary">
            Existing Tracking Numbers:
          </Typography>
        </Grid>
      </Grid>

      <div className="limit-height">
        {props.wos_doc.trackingNumbers !== undefined ? (
          props.wos_doc.trackingNumbers.map((tn, i) => {
            return <div key={'tn' + i.toString()}>{tn}</div>;
          })
        ) : (
          <div />
        )}
      </div>
    </Grid>
  );
};

export default WoData;
