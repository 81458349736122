import _ from 'lodash';
import { actionTypes } from '../actions/df_workunits.ac';
import { stageDeadlines } from '../components/WorkUnits/WorkunitDetail/utils';

export const initialState = {
  events: [],
  isLoadingEvents: false,
  loadEventError: false,
  existingClaim: { isFetching: false, data: null, error: null },
  workunitDetail: { isFetching: false, data: null, error: null },
  noteTemplates: { isFetching: false, data: null, error: null },
  viewProps: {
    phaseOpen: {},
  },
  workunitState: {},
  isRefactoringHDMSAccounts: false,
  refactorHDMSAccountsError: null,
  isSavingWorkunitState: false,
  saveWorkunitStateError: null,
  isClaimingWorkunit: false,
  isResettingWorkunit: false,
  claimError: null,
  isTogglingWorkunitCancel: false,
  toggleCancelError: null,
  isReleasingWorkunit: false,
  isAcknowledgingAlert: false,
  releaseError: null,
  notes: [],
  isAddingNote: false,
  addNoteError: null,
  workunitStatusSummary: { isFetching: false, data: {}, error: null },
  workunitOrderStatus: { isFetching: false, data: {}, error: null },
  workunitPeriodReport: { isFetching: false, data: {}, error: null },
  activePendingReasons: { isFetching: false, data: [], error: null },
  activeDoc: null,

  // Team re-assign
  isReassigningTeam: false,
  teamReassignError: null,

  // Global filter policies

  activeGlobalFilterPolicy: null,
  activeGlobalFilterPolicyError: null,
  isFetchingActiveGlobalFilterPolicy: false,

  // Parachute
  isFetchingParachuteFulfillmentStatuses: false,
  parachuteFulfillmentStatuses: [],
  parachuteFulfillmentStatusesError: null,

  isAddingParachuteComment: false,
  addParachuteCommentError: null,

  isLinkingParachuteHdmsWIds: false,
  parachuteLinkHdmsWIdsError: null,

  isUpdatingParachuteDeliveryUpdate: false,
  updateParachuteDeliveryUpdateError: null,

  parachuteOrderDetails: null,
  parachuteOrderDetailsError: null,
  isFetchingParachuteOrderDetails: false,

  isAcceptingParachuteOrder: false,
  acceptParachuteOrderError: null,

  isUnarchivingWorkunit: false,
  unarchiveWorkunitError: null,
};

export function dfWorkunit(state = initialState, action) {
  switch (action.type) {
    //Events
    case actionTypes.FETCH_WU_EVENTS_PENDING:
      return {
        ...state,
        isLoadingEvents: true,
      };

    case actionTypes.FETCH_WU_EVENTS_SUCCESS:
      return {
        ...state,
        isLoadingEvents: false,
        events: action.data,
      };

    case actionTypes.FETCH_WU_EVENTS_ERROR:
      return {
        ...state,
        isLoadingEvents: false,
        loadEventError: action.error,
      };

    case actionTypes.LOG_WU_EVENT:
      return {
        ...state,
        events: [...state.events, action.data],
      };

    // Order status
    case actionTypes.FETCH_WU_ORDER_STATUS_PENDING:
      return {
        ...state,
        workunitOrderStatus: {
          ...state.workunitOrderStatus,
          isFetching: true,
        },
      };

    case actionTypes.FETCH_WU_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        workunitOrderStatus: {
          ...state.workunitOrderStatus,
          isFetching: false,
          data: action.data,
        },
      };

    case actionTypes.FETCH_WU_ORDER_STATUS_ERROR:
      return {
        ...state,
        workunitOrderStatus: {
          ...state.workunitOrderStatus,
          isFetching: false,
          error: action.error,
        },
      };

    // Global filter policies

    case actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_PENDING:
      return {
        ...state,
        isFetchingActiveGlobalFilterPolicy: true,
        activeGlobalFilterPolicyError: null,
      };

    case actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_SUCCESS:
      return {
        ...state,
        isFetchingActiveGlobalFilterPolicy: false,
        activeGlobalFilterPolicyError: null,
        activeGlobalFilterPolicy: action.data,
      };

    case actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_ERROR:
      return {
        ...state,
        isFetchingActiveGlobalFilterPolicy: false,
        activeGlobalFilterPolicyError: action.error,
      };

    // Existing claim fetch
    case actionTypes.FETCH_EXISTING_CLAIM_PENDING:
      return {
        ...state,
        existingClaim: { ...state.existingClaim, isFetching: true },
      };

    case actionTypes.FETCH_EXISTING_CLAIM_SUCCESS:
      return {
        ...state,
        existingClaim: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      };

    case actionTypes.FETCH_EXISTING_CLAIM_ERROR:
      return {
        ...state,
        existingClaim: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      };

    case actionTypes.FLUSH_EXISTING_CLAIM:
      return {
        ...state,
        existingClaim: {
          isFetching: false,
          data: null,
          error: null,
        },
      };

    // Detail fetch
    case actionTypes.FETCH_WORKUNIT_DETAIL_PENDING:
    case actionTypes.CLAIM_NEXT_WORKUNIT_PENDING:
      return {
        ...state,
        workunitDetail: { isFetching: true, data: null, error: null },
        workunitState: {},
      };

    case actionTypes.FETCH_WORKUNIT_DETAIL_SUCCESS:
    case actionTypes.CLAIM_NEXT_WORKUNIT_SUCCESS:
      return {
        ...state,
        workunitDetail: {
          isFetching: false,
          data: action.data,
          error: null,
        },
        workunitState: _.cloneDeep(action.data),
        viewProps: {
          ...state.viewProps,
          phaseOpen: {
            [action.data?.bu_state]: true,
          },
        },
        activeDoc: null,
        notes: action.data?.notes?.reverse() || [],
        events: [],
      };

    case actionTypes.FETCH_WORKUNIT_DETAIL_ERROR:
    case actionTypes.CLAIM_NEXT_WORKUNIT_ERROR:
      return {
        ...state,
        workunitDetail: {
          isFetching: false,
          data: null,
          error: action.error,
        },
        workunitState: {},
      };

    // Workunit explicit claim/release  //

    case actionTypes.CLAIM_GIVEN_WORKUNIT_PENDING:
      return {
        ...state,
        isClaimingWorkunit: true,
        claimError: null,
      };

    case actionTypes.CLAIM_GIVEN_WORKUNIT_SUCCESS:
      return {
        ...state,
        isClaimingWorkunit: false,
        claimError: null,
        workunitDetail: {
          isFetching: false,
          data: { ...state.workunitDetail.data, ...action.data },
          error: null,
        },
        workunitState: { ...state.workunitState, ...action.data },
      };

    case actionTypes.CLAIM_GIVEN_WORKUNIT_ERROR:
      return {
        ...state,
        isClaimingWorkunit: false,
        claimError: action.error,
      };

    // Releasing a workunit

    case actionTypes.RELEASE_WORKUNIT_PENDING:
      return {
        ...state,
        isReleasingWorkunit: true,
        releaseError: null,
      };

    case actionTypes.RELEASE_WORKUNIT_SUCCESS:
      return {
        ...state,
        isReleasingWorkunit: false,
        releaseError: null,
      };

    case actionTypes.RELEASE_WORKUNIT_ERROR:
      return {
        ...state,
        isReleasingWorkunit: false,
        releaseError: action.error,
      };

    // Workunit team reassign

    case actionTypes.REASSIGN_WORKUNIT_TEAM_PENDING:
      return {
        ...state,
        isReassigningTeam: true,
        teamReassignError: null,
      };

    case actionTypes.REASSIGN_WORKUNIT_TEAM_SUCCESS:
      return {
        ...state,
        isReassigningTeam: false,
        teamReassignError: null,
        workunitDetail: {
          isFetching: false,
          data: { ...state.workunitDetail.data, ...action.data },
          error: null,
        },
        workunitState: { ...state.workunitState, ...action.data },
      };

    case actionTypes.REASSIGN_WORKUNIT_TEAM_ERROR:
      return {
        ...state,
        isReassigningTeam: false,
        teamReassignError: action.error,
      };

    // Acknowledging a workunit alert

    case actionTypes.ACKNOWLEDGE_WU_ALERT_PENDING:
      return {
        ...state,
        isAcknowledgingAlert: true,
        acknowledgeError: null,
      };

    case actionTypes.ACKNOWLEDGE_WU_ALERT_SUCCESS:
      const updatedAlert = _.omit(state.workunitState.alerts, [
        action.data.alertKey,
      ]);
      return {
        ...state,
        workunitState: { ...state.workunitState, alerts: updatedAlert },
        isAcknowledgingAlert: false,
        acknowledgeError: null,
      };

    case actionTypes.ACKNOWLEDGE_WU_ALERT_ERROR:
      return {
        ...state,
        isAcknowledgingAlert: false,
        acknowledgeError: action.error,
      };

    // Resetting a workunit

    case actionTypes.RESET_WORKUNIT_PENDING:
      return {
        ...state,
        isResettingWorkunit: true,
        resetError: null,
      };

    case actionTypes.RESET_WORKUNIT_SUCCESS:
      return {
        ...state,
        isResettingWorkunit: false,
        resetError: null,
        workunitDetail: {
          isFetching: false,
          data: action.data,
          error: null,
        },
        workunitState: _.cloneDeep(action.data),
        viewProps: {
          ...state.viewProps,
          phaseOpen: {
            [action.data?.bu_state]: true,
          },
        },
        activeDoc: null,
      };

    case actionTypes.RESET_WORKUNIT_ERROR:
      return {
        ...state,
        isResettingWorkunit: false,
        resetError: action.error,
      };

    // Cancel/uncancel a workunit

    case actionTypes.TOGGLE_WORKUNIT_CANCEL_PENDING:
      return {
        ...state,
        isTogglingWorkunitCancel: true,
        toggleCancelError: null,
      };

    case actionTypes.TOGGLE_WORKUNIT_CANCEL_SUCCESS:
      return {
        ...state,
        isTogglingWorkunitCancel: false,
        toggleCancelError: null,
        workunitDetail: {
          isFetching: false,
          data: { ...state.workunitDetail.data, ...action.data },
          error: null,
        },
        workunitState: { ...state.workunitState, ...action.data },
      };

    case actionTypes.TOGGLE_WORKUNIT_CANCEL_ERROR:
      return {
        ...state,
        isTogglingWorkunitCancel: false,
        toggleCancelError: action.error,
      };

    // Unarchive workunit

    case actionTypes.UNARCHIVE_WORKUNIT_PENDING:
      return {
        ...state,
        isUnarchivingWorkunit: true,
        unarchiveWorkunitError: null,
      };

    case actionTypes.UNARCHIVE_WORKUNIT_SUCCESS:
      return {
        ...state,
        isUnarchivingWorkunit: false,
        unarchiveWorkunitError: null,
        workunitDetail: {
          isFetching: false,
          data: { ...state.workunitDetail.data, archived_ts: null },
          error: null,
        },
        workunitState: { ...state.workunitState, archived_ts: null },
      };

    case actionTypes.UNARCHIVE_WORKUNIT_ERROR:
      return {
        ...state,
        isUnarchivingWorkunit: false,
        unarchiveWorkunitError: action.error,
      };

    // Workunit saving //

    case actionTypes.SAVE_WORKUNIT_STATE_PENDING:
      return {
        ...state,
        isSavingWorkunitState: true,
        saveWorkunitStateError: null,
      };

    case actionTypes.SAVE_WORKUNIT_STATE_SUCCESS:
      return {
        ...state,
        isSavingWorkunitState: false,
        saveWorkunitStateError: null,
        workunitState: {
          ...state.workunitState,
          ...action.data,
        },
      };

    case actionTypes.SAVE_WORKUNIT_STATE_ERROR:
      return {
        ...state,
        isSavingWorkunitState: false,
        saveWorkunitStateError: action.error,
      };

    // Workunit notes

    case actionTypes.FETCH_NOTE_TEMPLATES_PENDING:
      return {
        ...state,
        noteTemplates: { isFetching: true, data: null, error: null },
      };

    case actionTypes.FETCH_NOTE_TEMPLATES_SUCCESS:
      return {
        ...state,
        noteTemplates: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      };

    case actionTypes.FETCH_NOTE_TEMPLATES_ERROR:
      return {
        ...state,
        noteTemplates: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      };

    case actionTypes.ADD_WORKUNIT_NOTE_PENDING:
      return {
        ...state,
        isAddingNote: true,
        addNoteError: null,
      };

    case actionTypes.ADD_WORKUNIT_NOTE_SUCCESS:
      return {
        ...state,
        isAddingNote: false,
        addNoteError: null,
        notes: [action.data, ...state.notes],
      };

    case actionTypes.ADD_WORKUNIT_NOTE_ERROR:
      return {
        ...state,
        isAddingNote: false,
        addNoteError: action.error,
      };

    // Status summary fetch

    case actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_PENDING:
      return {
        ...state,
        workunitStatusSummary: { isFetching: true, data: null, error: null },
      };
    case actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_SUCCESS:
      return {
        ...state,
        workunitStatusSummary: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      };
    case actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_ERROR:
      return {
        ...state,
        workunitStatusSummary: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      };

    // Active pending reasons fetch

    case actionTypes.FETCH_PENDING_REASON_REPORT_SUCCESS:
      return {
        ...state,
        activePendingReasons: {
          isFetching: false,
          data: action.data || [],
          error: null,
        },
      };

    case actionTypes.FETCH_PENDING_REASON_REPORT_ERROR:
      return {
        ...state,
        activePendingReasons: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      };

    case actionTypes.FETCH_PENDING_REASON_REPORT_PENDING:
      return {
        ...state,
        activePendingReasons: { isFetching: true, data: [], error: null },
      };

    // Period report fetch

    case actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_PENDING:
      return {
        ...state,
        workunitPeriodReport: { isFetching: true, data: null, error: null },
      };
    case actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_SUCCESS:
      return {
        ...state,
        workunitPeriodReport: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      };
    case actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_ERROR:
      return {
        ...state,
        workunitPeriodReport: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      };

    case actionTypes.SET_ACTIVE_DOC:
      return {
        ...state,
        activeDoc: action.doc,
      };

    case actionTypes.SET_DOC_ROTATION_PREF:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          docs: state.workunitState.docs.map((doc) =>
            doc.id + doc.faxJobIdx === action.docId
              ? {
                  ...doc,
                  rotation: action.rotationPref,
                }
              : doc
          ),
        },
      };

    // State manipulation

    case actionTypes.REFACTOR_HDMS_ACCOUNTS_PENDING:
      return {
        ...state,
        isRefactoringHDMSAccounts: true,
        refactorHDMSAccountsError: null,
      };

    case actionTypes.REFACTOR_HDMS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          ...action.data,
        },
        isRefactoringHDMSAccounts: false,
        refactorHDMSAccountsError: null,
      };

    case actionTypes.REFACTOR_HDMS_ACCOUNTS_ERROR:
      return {
        ...state,
        isRefactoringHDMSAccounts: false,
        refactorHDMSAccountsError: action.error,
      };

    case actionTypes.SET_HDMS_ACCOUNTS:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          hdmsAccts: action.accounts,
        },
      };

    case actionTypes.SET_WORKUNIT_PROP:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          [action.key]: action.value,
        },
      };

    case actionTypes.SET_PHASE_PROP:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return action.patch
                ? {
                    ...phase,
                    ...action.prop,
                  }
                : action.prop;
            }

            return phase;
          }),
        },
      };

    case actionTypes.SET_PHASE_STATUS:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                status: action.status,
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.SET_PHASE_NOTE:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                note: action.note,
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.SET_CHECKBOX_KEY_VALUE:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                ...(Boolean(phase.dq_state)
                  ? Boolean(action.product)
                    ? {
                        dq_state: phase.dq_state.map((product) => {
                          if (product.nm === action.product) {
                            return {
                              ...product,
                              checkbox_state: product.checkbox_state.map(
                                (checkbox) => {
                                  if (checkbox.nm === action.checkbox) {
                                    return action.key
                                      ? {
                                          ...checkbox,
                                          [action.key]: action.value,
                                        }
                                      : {
                                          ...checkbox,
                                          ...action.value,
                                        };
                                  }
                                  return checkbox;
                                }
                              ),
                            };
                          }
                          return product;
                        }),
                      }
                    : {
                        dq_state: phase.dq_state.map((product) => {
                          return {
                            ...product,
                            checkbox_state: product.checkbox_state.map(
                              (checkbox) => {
                                if (checkbox.nm === action.checkbox) {
                                  return action.key
                                    ? {
                                        ...checkbox,
                                        [action.key]: action.value,
                                      }
                                    : {
                                        ...checkbox,
                                        ...action.value,
                                      };
                                }
                                return checkbox;
                              }
                            ),
                          };
                        }),
                      }
                  : {
                      checkbox_state: phase.checkbox_state.map((checkbox) => {
                        if (checkbox.nm === action.checkbox) {
                          return action.key
                            ? {
                                ...checkbox,
                                [action.key]: action.value,
                              }
                            : {
                                ...checkbox,
                                ...action.value,
                              };
                        }
                        return checkbox;
                      }),
                    }),
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.SET_PRODUCT_KEY_VALUE:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: (state.workunitState.phase_state || []).map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                dq_state: (phase.dq_state || []).map((product) => {
                  if (product.nm === action.product) {
                    return {
                      ...product,
                      [action.key]: action.value,
                    };
                  }
                  return product;
                }),
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.UPDATE_PHASE_PRODUCT:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                dq_state: (phase.dq_state || []).map((product) => {
                  if (product.nm === action.product) {
                    return {
                      ...product,
                      ...action.product,
                    };
                  }
                  return product;
                }),
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.SET_HDMS_WORKUNIT_IDS:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          hdmsWIds: action.numbers,
        },
      };

    case actionTypes.UPDATE_PHASE_EXTENDED_SLA:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: state.workunitState.phase_state.map((phase) => {
            if (phase.phase === action.phase) {
              return {
                ...phase,
                sla: {
                  ...phase.sla,
                  extended_minutes: action.sla,
                },
              };
            }
            return phase;
          }),
        },
      };

    case actionTypes.REFACTOR_WORKUNIT_STATE:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          ...action.newState,
        },
      };

    case actionTypes.SET_STATE_ITEM:
      return {
        ...state,
        [action.key]: action.value,
      };

    case actionTypes.FLUSH_STATE:
      return initialState;

    case actionTypes.STAGE_SLA_DEADLINES:
      return {
        ...state,
        workunitState: {
          ...state.workunitState,
          phase_state: stageDeadlines(
            state.workunitState.phase_state,
            action.phase,
            action.unset,
            action.saveMode
          ),
        },
      };

    case actionTypes.SET_VIEW_PROPS:
      return {
        ...state,
        viewProps: {
          ...state.viewProps,
          [action.prop]: action.key
            ? {
                ...(state.viewProps[action.prop] || {}),
                [action.key]: [action.value],
              }
            : action.patch
            ? {
                ...state.viewProps[action.prop],
                ...action.value,
              }
            : action.value,
        },
      };

    case actionTypes.SAVE_WORKUNIT:
      return {
        ...state,
        workunitState: action.data,
      };

    // Parachute
    case actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_PENDING:
      return {
        ...state,
        isLoadingParachuteFulfillmentStatuses: true,
        parachuteFulfillmentStatusesError: null,
      };

    case actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_SUCCESS:
      return {
        ...state,
        isLoadingParachuteFulfillmentStatuses: false,
        parachuteFulfillmentStatuses: action.data,
        parachuteFulfillmentStatusesError: null,
      };

    case actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_ERROR:
      return {
        ...state,
        isLoadingParachuteFulfillmentStatuses: false,
        parachuteFulfillmentStatusesError: action.error,
      };

    case actionTypes.PARACHUTE_ADD_COMMENT_PENDING:
      return {
        ...state,
        isAddingParachuteComment: true,
        addParachuteCommentError: null,
      };

    case actionTypes.PARACHUTE_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        isAddingParachuteComment: false,
        addParachuteCommentError: null,
      };

    case actionTypes.PARACHUTE_ADD_COMMENT_ERROR:
      return {
        ...state,
        isAddingParachuteComment: false,
        addParachuteCommentError: action.error,
      };

    case actionTypes.PARACHUTE_ACCEPT_ORDER_PENDING:
      return {
        ...state,
        isAcceptingParachuteOrder: true,
        acceptParachuteOrderError: null,
      };

    case actionTypes.PARACHUTE_ACCEPT_ORDER_SUCCESS:
      return {
        ...state,
        isAcceptingParachuteOrder: false,
        acceptParachuteOrderError: null,
      };

    case actionTypes.PARACHUTE_ACCEPT_ORDER_ERROR:
      return {
        ...state,
        isAcceptingParachuteOrder: false,
        acceptParachuteOrderError: action.error,
      };

    case actionTypes.PARACHUTE_DELIVERY_UPDATE_PENDING:
      return {
        ...state,
        isUpdatingParachuteDeliveryUpdate: true,
        updateParachuteDeliveryUpdateError: null,
      };

    case actionTypes.PARACHUTE_DELIVERY_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingParachuteDeliveryUpdate: false,
        updateParachuteDeliveryUpdateError: null,
      };

    case actionTypes.PARACHUTE_DELIVERY_UPDATE_ERROR:
      return {
        ...state,
        isUpdatingParachuteDeliveryUpdate: false,
        updateParachuteDeliveryUpdateError: action.error,
      };

    case actionTypes.PARACHUTE_LINK_HDMS_WIDS_SUCCESS:
      return {
        ...state,
        isLinkingParachuteHdmsWIds: false,
        parachuteLinkHdmsWIdsError: null,
        workunitState: {
          ...state.workunitState,
          e_referral: {
            ...state.workunitState.e_referral,
            hdmsWIdLinks: action.data,
          },
        },
      };

    case actionTypes.PARACHUTE_LINK_HDMS_WIDS_PENDING:
      return {
        ...state,
        isLinkingParachuteHdmsWIds: true,
        parachuteLinkHdmsWIdsError: null,
      };

    case actionTypes.PARACHUTE_LINK_HDMS_WIDS_ERROR:
      return {
        ...state,
        isLinkingParachuteHdmsWIds: false,
        parachuteLinkHdmsWIdsError: action.error,
      };

    case actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingParachuteOrderDetails: false,
        parachuteOrderDetails: action.data,
        parachuteOrderDetailsError: null,
      };

    case actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_PENDING:
      return {
        ...state,
        isFetchingParachuteOrderDetails: true,
        parachuteOrderDetailsError: null,
      };

    case actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_ERROR:
      return {
        ...state,
        isFetchingParachuteOrderDetails: false,
        parachuteOrderDetailsError: action.error,
      };

    case actionTypes.FLUSH_PARACHUTE_MODAL_ERRORS:
      return {
        ...state,
        addParachuteCommentError: null,
        parachuteLinkHdmsWIdsError: null,
        updateParachuteDeliveryUpdateError: null,
        parachuteOrderDetailsError: null,
        parachuteFulfillmentStatusesError: null,
        acceptParachuteOrderError: null,
      };

    default:
      return state;
  }
}
