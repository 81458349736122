import React from 'react';
import { Button, Grid } from '@material-ui/core';

import WoData from './WoData';

const PastTracks = ({
  data,
  updateWoField,
  validatePosInt,
  cancelTracks,
  resetWo,
}) => {
  return (
    <div className="row">
      <WoData
        wos_doc={data}
        updateWoField={updateWoField}
        validatePosInt={validatePosInt}
      />
      <div>
        Do you want to cancel these orders so that you can recreate the shipping
        notifs?
        <Grid container spacing={8}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={cancelTracks}>
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={resetWo}>
              No
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PastTracks;
