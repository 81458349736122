import { serialize } from '../util/object';

export const actionTypes = {
  FETCH_EXISTING_CLAIM_SUCCESS: 'FETCH_EXISTING_CLAIM_SUCCESS',
  FETCH_EXISTING_CLAIM_ERROR: 'FETCH_EXISTING_CLAIM_ERROR',
  FETCH_EXISTING_CLAIM_PENDING: 'FETCH_EXISTING_CLAIM_PENDING',

  FLUSH_EXISTING_CLAIM: 'FLUSH_EXISTING_CLAIM',

  FETCH_WORKUNITS_SUCCESS: 'FETCH_WORKUNITS_SUCCESS',
  FETCH_WORKUNITS_ERROR: 'FETCH_WORKUNITS_ERROR',
  FETCH_WORKUNITS_PENDING: 'FETCH_WORKUNITS_PENDING',

  FETCH_WORKUNIT_PERIOD_REPORTS_SUCCESS:
    'FETCH_WORKUNIT_PERIOD_REPORTS_SUCCESS',
  FETCH_WORKUNIT_PERIOD_REPORTS_ERROR: 'FETCH_WORKUNIT_PERIOD_REPORTS_ERROR',
  FETCH_WORKUNIT_PERIOD_REPORTS_PENDING:
    'FETCH_WORKUNIT_PERIOD_REPORTS_PENDING',

  FETCH_WORKUNIT_DETAIL_SUCCESS: 'FETCH_WORKUNIT_DETAIL_SUCCESS',
  FETCH_WORKUNIT_DETAIL_ERROR: 'FETCH_WORKUNIT_DETAIL_ERROR',
  FETCH_WORKUNIT_DETAIL_PENDING: 'FETCH_WORKUNIT_DETAIL_PENDING',

  REFACTOR_HDMS_ACCOUNTS_SUCCESS: 'REFACTOR_HDMS_ACCOUNTS_SUCCESS',
  REFACTOR_HDMS_ACCOUNTS_ERROR: 'REFACTOR_HDMS_ACCOUNTS_ERROR',
  REFACTOR_HDMS_ACCOUNTS_PENDING: 'REFACTOR_HDMS_ACCOUNTS_PENDING',

  CLAIM_NEXT_WORKUNIT_SUCCESS: 'CLAIM_NEXT_WORKUNIT_SUCCESS',
  CLAIM_NEXT_WORKUNIT_ERROR: 'CLAIM_NEXT_WORKUNIT_ERROR',
  CLAIM_NEXT_WORKUNIT_PENDING: 'CLAIM_NEXT_WORKUNIT_PENDING',

  CLAIM_GIVEN_WORKUNIT_SUCCESS: 'CLAIM_GIVEN_WORKUNIT_SUCCESS',
  CLAIM_GIVEN_WORKUNIT_ERROR: 'CLAIM_GIVEN_WORKUNIT_ERROR',
  CLAIM_GIVEN_WORKUNIT_PENDING: 'CLAIM_GIVEN_WORKUNIT_PENDING',

  RELEASE_WORKUNIT_SUCCESS: 'RELEASE_WORKUNIT_SUCCESS',
  RELEASE_WORKUNIT_ERROR: 'RELEASE_WORKUNIT_ERROR',
  RELEASE_WORKUNIT_PENDING: 'RELEASE_WORKUNIT_PENDING',

  ACKNOWLEDGE_WU_ALERT_SUCCESS: 'ACKNOWLEDGE_WU_ALERT_SUCCESS',
  ACKNOWLEDGE_WU_ALERT_ERROR: 'ACKNOWLEDGE_WU_ALERT_ERROR',
  ACKNOWLEDGE_WU_ALERT_PENDING: 'ACKNOWLEDGE_WU_ALERT_PENDING',

  RESET_WORKUNIT_SUCCESS: 'RESET_WORKUNIT_SUCCESS',
  RESET_WORKUNIT_ERROR: 'RESET_WORKUNIT_ERROR',
  RESET_WORKUNIT_PENDING: 'RESET_WORKUNIT_PENDING',

  TOGGLE_WORKUNIT_CANCEL_SUCCESS: 'TOGGLE_WORKUNIT_CANCEL_SUCCESS',
  TOGGLE_WORKUNIT_CANCEL_ERROR: 'TOGGLE_WORKUNIT_CANCEL_ERROR',
  TOGGLE_WORKUNIT_CANCEL_PENDING: 'TOGGLE_WORKUNIT_CANCEL_PENDING',

  UNARCHIVE_WORKUNIT_SUCCESS: 'UNARCHIVE_WORKUNIT_SUCCESS',
  UNARCHIVE_WORKUNIT_ERROR: 'UNARCHIVE_WORKUNIT_ERROR',
  UNARCHIVE_WORKUNIT_PENDING: 'UNARCHIVE_WORKUNIT_PENDING',

  SAVE_WORKUNIT_STATE_SUCCESS: 'SAVE_WORKUNIT_STATE_SUCCESS',
  SAVE_WORKUNIT_STATE_ERROR: 'SAVE_WORKUNIT_STATE_ERROR',
  SAVE_WORKUNIT_STATE_PENDING: 'SAVE_WORKUNIT_STATE_PENDING',

  FETCH_NOTE_TEMPLATES_SUCCESS: 'FETCH_NOTE_TEMPLATES_SUCCESS',
  FETCH_NOTE_TEMPLATES_ERROR: 'FETCH_NOTE_TEMPLATES_ERROR',
  FETCH_NOTE_TEMPLATES_PENDING: 'FETCH_NOTE_TEMPLATES_PENDING',

  ADD_WORKUNIT_NOTE_SUCCESS: 'ADD_WORKUNIT_NOTE_SUCCESS',
  ADD_WORKUNIT_NOTE_ERROR: 'ADD_WORKUNIT_NOTE_ERROR',
  ADD_WORKUNIT_NOTE_PENDING: 'ADD_WORKUNIT_NOTE_PENDING',

  FETCH_WORKUNITS_STATUS_SUMMARY_SUCCESS:
    'FETCH_WORKUNITS_STATUS_SUMMARY_SUCCESS',
  FETCH_WORKUNITS_STATUS_SUMMARY_ERROR: 'FETCH_WORKUNITS_STATUS_SUMMARY_ERROR',
  FETCH_WORKUNITS_STATUS_SUMMARY_PENDING:
    'FETCH_WORKUNITS_STATUS_SUMMARY_PENDING',

  SET_ACTIVE_DOC: 'SET_ACTIVE_DOC',
  SET_DOC_ROTATION_PREF: 'SET_DOC_ROTATION_PREF',

  SET_HDMS_ACCOUNTS: 'SET_HDMS_ACCOUNTS',
  SET_PHASE_STATUS: 'SET_PHASE_STATUS',
  SET_PHASE_NOTE: 'SET_PHASE_NOTE',
  SET_CHECKBOX_KEY_VALUE: 'SET_CHECKBOX_KEY_VALUE',

  SET_HDMS_WORKUNIT_IDS: 'SET_HDMS_WORKUNIT_IDS',
  UPDATE_PHASE_EXTENDED_SLA: 'UPDATE_PHASE_EXTENDED_SLA',

  SET_PRODUCT_KEY_VALUE: 'SET_PRODUCT_KEY_VALUE',
  UPDATE_PHASE_PRODUCT: 'UPDATE_PHASE_PRODUCT',

  REFACTOR_WORKUNIT_STATE: 'REFACTOR_WORKUNIT_STATE',

  SET_STATE_ITEM: 'SET_STATE_ITEM',
  SET_WORKUNIT_PROP: 'SET_WORKUNIT_PROP',
  SET_PHASE_PROP: 'SET_PHASE_PROP',

  FLUSH_STATE: 'FLUSH_STATE',

  STAGE_SLA_DEADLINES: 'STAGE_SLA_DEADLINES',

  SET_VIEW_PROPS: 'SET_VIEW_PROPS',

  FETCH_WU_EVENTS_SUCCESS: 'FETCH_WU_EVENTS_SUCCESS',
  FETCH_WU_EVENTS_ERROR: 'FETCH_WU_EVENTS_ERROR',
  FETCH_WU_EVENTS_PENDING: 'FETCH_WU_EVENTS_PENDING',

  FETCH_WU_ORDER_STATUS_SUCCESS: 'FETCH_WU_ORDER_STATUS_SUCCESS',
  FETCH_WU_ORDER_STATUS_ERROR: 'FETCH_WU_ORDER_STATUS_ERROR',
  FETCH_WU_ORDER_STATUS_PENDING: 'FETCH_WU_ORDER_STATUS_PENDING',

  LOG_WU_EVENT: 'LOG_WU_EVENT',

  FETCH_PENDING_REASON_REPORT_SUCCESS: 'FETCH_PENDING_REASON_REPORT_SUCCESS',
  FETCH_PENDING_REASON_REPORT_ERROR: 'FETCH_PENDING_REASON_REPORT_ERROR',
  FETCH_PENDING_REASON_REPORT_PENDING: 'FETCH_PENDING_REASON_REPORT_PENDING',

  SAVE_WORKUNIT: 'SAVE_WORKUNIT',

  REASSIGN_WORKUNIT_TEAM_SUCCESS: 'REASSIGN_WORKUNIT_TEAM_SUCCESS',
  REASSIGN_WORKUNIT_TEAM_ERROR: 'REASSIGN_WORKUNIT_TEAM_ERROR',
  REASSIGN_WORKUNIT_TEAM_PENDING: 'REASSIGN_WORKUNIT_TEAM_PENDING',

  FETCH_ACTIVE_GLOBAL_FILTERS_SUCCESS: 'FETCH_ACTIVE_GLOBAL_FILTERS_SUCCESS',
  FETCH_ACTIVE_GLOBAL_FILTERS_ERROR: 'FETCH_ACTIVE_GLOBAL_FILTERS_ERROR',
  FETCH_ACTIVE_GLOBAL_FILTERS_PENDING: 'FETCH_ACTIVE_GLOBAL_FILTERS_PENDING',

  FETCH_PARACHUTE_FULFILLMENT_STATUSES_ERROR:
    'FETCH_PARACHUTE_FULFILLMENT_STATUSES_ERROR',
  FETCH_PARACHUTE_FULFILLMENT_STATUSES_PENDING:
    'FETCH_PARACHUTE_FULFILLMENT_STATUSES_PENDING',
  FETCH_PARACHUTE_FULFILLMENT_STATUSES_SUCCESS:
    'FETCH_PARACHUTE_FULFILLMENT_STATUSES_SUCCESS',

  PARACHUTE_ADD_COMMENT_ERROR: 'PARACHUTE_ADD_COMMENT_ERROR',
  PARACHUTE_ADD_COMMENT_PENDING: 'PARACHUTE_ADD_COMMENT_PENDING',
  PARACHUTE_ADD_COMMENT_SUCCESS: 'PARACHUTE_ADD_COMMENT_SUCCESS',

  PARACHUTE_ACCEPT_ORDER_ERROR: 'PARACHUTE_ACCEPT_ORDER_ERROR',
  PARACHUTE_ACCEPT_ORDER_PENDING: 'PARACHUTE_ACCEPT_ORDER_PENDING',
  PARACHUTE_ACCEPT_ORDER_SUCCESS: 'PARACHUTE_ACCEPT_ORDER_SUCCESS',

  PARACHUTE_DELIVERY_UPDATE_ERROR: 'PARACHUTE_DELIVERY_UPDATE_ERROR',
  PARACHUTE_DELIVERY_UPDATE_PENDING: 'PARACHUTE_DELIVERY_UPDATE_PENDING',
  PARACHUTE_DELIVERY_UPDATE_SUCCESS: 'PARACHUTE_DELIVERY_UPDATE_SUCCESS',

  FETCH_PARACHUTE_ORDER_DETAILS_ERROR: 'FETCH_PARACHUTE_ORDER_DETAILS_ERROR',
  FETCH_PARACHUTE_ORDER_DETAILS_PENDING:
    'FETCH_PARACHUTE_ORDER_DETAILS_PENDING',
  FETCH_PARACHUTE_ORDER_DETAILS_SUCCESS:
    'FETCH_PARACHUTE_ORDER_DETAILS_SUCCESS',

  PARACHUTE_LINK_HDMS_WIDS_ERROR: 'PARACHUTE_LINK_HDMS_WIDS_ERROR',
  PARACHUTE_LINK_HDMS_WIDS_PENDING: 'PARACHUTE_LINK_HDMS_WIDS_PENDING',
  PARACHUTE_LINK_HDMS_WIDS_SUCCESS: 'PARACHUTE_LINK_HDMS_WIDS_SUCCESS',

  FLUSH_PARACHUTE_MODAL_ERRORS: 'FLUSH_PARACHUTE_MODAL_ERRORS',
};

export function flushParachuteModalErrors_ac() {
  return {
    type: actionTypes.FLUSH_PARACHUTE_MODAL_ERRORS,
  };
}

export function fetchParachuteFulfillmentStatuses_ac() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_PENDING,
    });

    return window.sch
      .get('/mock/parachute/fulfillment_statuses')
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_PARACHUTE_FULFILLMENT_STATUSES_ERROR,
          error,
        });
      });
  };
}

export function fetchParachuteOrderDetails_ac(params) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_PENDING,
    });

    return window.sch
      .get(`/api/parachute_api/order_details/${params.referral_id}`, {})
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_SUCCESS,
          data: res.order,
        });

        return res.order;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_PARACHUTE_ORDER_DETAILS_ERROR,
          error,
        });
      });
  };
}

export function parachuteAddComment_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PARACHUTE_ADD_COMMENT_PENDING });

    return window.sch
      .post('/api/parachute_api/comments/add', params)
      .then((res) => {
        if (res.error || !res.success) {
          if (onError) {
            onError();
          }
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.PARACHUTE_ADD_COMMENT_SUCCESS,
          data: res.data,
        });

        if (onSuccess) {
          onSuccess(res.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
        dispatch({
          type: actionTypes.PARACHUTE_ADD_COMMENT_ERROR,
          error,
        });
      });
  };
}

export function parachuteAcceptOrder_ac(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PARACHUTE_ACCEPT_ORDER_PENDING });

    return window.sch
      .post('/api/parachute_api/accept_order', payload)
      .then((res) => {
        if (res.error || !res.success) {
          if (onError) {
            onError();
          }
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.PARACHUTE_ACCEPT_ORDER_SUCCESS,
          data: res.data,
        });

        if (onSuccess) {
          onSuccess(res.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
        dispatch({
          type: actionTypes.PARACHUTE_ACCEPT_ORDER_ERROR,
          error,
        });
      });
  };
}

export function parachuteLinkHDMSWIDs_ac(
  vId,
  hdmsWIdLinks,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PARACHUTE_LINK_HDMS_WIDS_PENDING });

    return window.sch
      .post('/api/df/workunits/parachute_fulfillment_wo_link', {
        vId,
        hdmsWIdLinks,
      })
      .then((res) => {
        if (res.error || !res.success) {
          if (onError) {
            onError();
          }

          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.PARACHUTE_LINK_HDMS_WIDS_SUCCESS,
          data: hdmsWIdLinks,
        });

        if (onSuccess) {
          onSuccess(res.data);
        }

        return res.data;
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
        dispatch({
          type: actionTypes.PARACHUTE_LINK_HDMS_WIDS_ERROR,
          error,
        });

        return error;
      });
  };
}

export function parachuteDeliveryUpdate_ac(
  params,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PARACHUTE_DELIVERY_UPDATE_PENDING });

    return window.sch
      .post('/api/parachute_api/delivery_update', params)
      .then((res) => {
        if (res.error || !res.success) {
          if (onError) {
            onError();
          }
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.PARACHUTE_DELIVERY_UPDATE_SUCCESS,
          data: res.data,
        });

        if (onSuccess) {
          onSuccess(res.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
        dispatch({
          type: actionTypes.PARACHUTE_DELIVERY_UPDATE_ERROR,
          error,
        });
      });
  };
}

export function fetchActiveGlobalFilters_ac(
  params,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_PENDING });

    return window.sch
      .post(`/api/df/workunits/global-filter-policies/active`, params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_SUCCESS,
          data: result.policy,
        });
        if (onSuccess) {
          onSuccess(result.policy);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_ACTIVE_GLOBAL_FILTERS_ERROR,
          error,
        });
      });
  };
}

export function fetchExistingClaim_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_EXISTING_CLAIM_PENDING });

    return window.sch
      .post(`/api/df/workunits/claims`, params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_EXISTING_CLAIM_SUCCESS,
          data: result.workunits,
        });
        if (onSuccess) {
          onSuccess(result.workunits);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_EXISTING_CLAIM_ERROR,
          error,
        });
      });
  };
}

export function flushExistingClaim_ac() {
  return {
    type: actionTypes.FLUSH_EXISTING_CLAIM,
  };
}

export function fetchWorkunitEvents_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_WU_EVENTS_PENDING });

    return window.sch
      .post('/api/df/workunits/events', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_WU_EVENTS_SUCCESS,
          data: result.events,
        });
        if (onSuccess) {
          onSuccess(result.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_WU_EVENTS_ERROR,
          error,
        });
      });
  };
}

export function fetchWorkunitOrderStatus_ac(Vid, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_WU_ORDER_STATUS_PENDING });

    return window.sch
      .get(`/api/df/cs_workunit/${Vid}`, {})
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_WU_ORDER_STATUS_SUCCESS,
          data: result.timelines,
        });

        if (onSuccess) {
          onSuccess(result.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_WU_ORDER_STATUS_ERROR,
          error,
        });
      });
  };
}

export function fetchPendingReasonReport_ac(params = {}, onlyActive = true) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_PENDING_REASON_REPORT_PENDING });

    return window.sch
      .post('/api/df/workunits/active_pending_reasons', params || {})
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }

        dispatch({
          type: actionTypes.FETCH_PENDING_REASON_REPORT_SUCCESS,
          data: onlyActive
            ? (res.results || []).filter((rs) => rs.effected_wu_count !== 0)
            : res.results,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_PENDING_REASON_REPORT_ERROR,
          error,
        });
      });
  };
}

export function logWUEvent_ac(params, { onSuccess, onError } = {}) {
  return async (dispatch, getState) => {
    try {
      const toLog = { ...params };
      const res = await window.sch.post(
        '/api/df/workunits/events/register',
        toLog
      );

      if (getState().dfWorkunit.events.length) {
        dispatch({
          type: actionTypes.LOG_WU_EVENT,
          data: res.event,
        });
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.log(`Error while logging an WU event: `, error);
      if (onError) {
        onError(error);
      }
    }
  };
}

export function refactorHDMSAccounts_ac(
  vId,
  updates = {},
  { onSuccess, onError } = {}
) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.REFACTOR_HDMS_ACCOUNTS_PENDING });

    try {
      const res = await window.sch.post('/api/df/workunits/accounts/update', {
        vId,
        updates,
      });

      dispatch({
        type: actionTypes.REFACTOR_HDMS_ACCOUNTS_SUCCESS,
        data: res.updates || updates,
      });

      if (onSuccess) {
        onSuccess(res.updates || updates);
      }
    } catch (error) {
      console.log(`Error on refactorHDMSAccounts: `, error);
      dispatch({
        type: actionTypes.REFACTOR_HDMS_ACCOUNTS_ERROR,
        error,
      });
      if (onError) {
        onError(error);
      }
    }
  };
}

export function fetchWorkunitDetail_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_WORKUNIT_DETAIL_PENDING });

    return window.sch
      .get('/api/df/workunits/detail?' + serialize(params), {})
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_WORKUNIT_DETAIL_SUCCESS,
          data: result.data,
        });
        if (onSuccess) {
          onSuccess(result.data);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_WORKUNIT_DETAIL_ERROR,
          error,
        });
      });
  };
}

export function claimNextWorkunit_ac(params, { onSuccess, onError } = {}) {
  const isExplicitClaim = Boolean(params.vIds?.length);
  const isAssign = Boolean(params.assignTo);

  // For manual claims, get the other pt wus info.
  params.attach_other_pt_wus = !isAssign;

  return (dispatch) => {
    dispatch({
      type: isExplicitClaim
        ? actionTypes.CLAIM_GIVEN_WORKUNIT_PENDING
        : actionTypes.CLAIM_NEXT_WORKUNIT_PENDING,
    });

    return window.sch
      .post('/api/df/workunits/claim', params)
      .then((result) => {
        if (result.error) {
          onError(result.error);
          throw new Error(result.error);
        }

        if (result[isAssign ? 'assigned' : 'claimed']) {
          dispatch({
            type: isExplicitClaim
              ? actionTypes.CLAIM_GIVEN_WORKUNIT_SUCCESS
              : actionTypes.CLAIM_NEXT_WORKUNIT_SUCCESS,
            data: result.workunit,
          });

          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          if (onError) {
            onError(result);
          }
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }

        dispatch({
          type: isExplicitClaim
            ? actionTypes.CLAIM_GIVEN_WORKUNIT_ERROR
            : actionTypes.CLAIM_NEXT_WORKUNIT_ERROR,
          error,
        });
      });
  };
}

export function reAssignTeam_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REASSIGN_WORKUNIT_TEAM_PENDING,
    });

    return window.sch
      .post('/api/df/workunits/re-assign-team', params)
      .then((result) => {
        if (result.error) {
          onError(result.error);
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.REASSIGN_WORKUNIT_TEAM_SUCCESS,
          data: result.workunit,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }

        dispatch({
          type: actionTypes.REASSIGN_WORKUNIT_TEAM_ERROR,
          error,
        });
      });
  };
}

export function releaseWorkunit_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.RELEASE_WORKUNIT_PENDING });

    return window.sch
      .post('/api/df/workunits/release', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        if (result.released) {
          dispatch({
            type: actionTypes.RELEASE_WORKUNIT_SUCCESS,
          });

          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          if (onError) {
            onError(result);
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.RELEASE_WORKUNIT_ERROR,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function acknowledgeWUAlert_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ACKNOWLEDGE_WU_ALERT_PENDING });

    return window.sch
      .post('/api/df/workunits/ack_alert', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.ACKNOWLEDGE_WU_ALERT_SUCCESS,
          data: params,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.ACKNOWLEDGE_WU_ALERT_ERROR,
          error,
        });
      });
  };
}

export function resetWorkunit_ac(
  vId,
  keep_claim = true,
  { onSuccess, onError } = {}
) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.RESET_WORKUNIT_PENDING });

    try {
      const result = await window.sch.post('/api/df/workunits/ready', {
        vIds: [vId],
        forced: true,
        keep_claim,
        sync_legacy_w: false,
      });

      if (result.error) {
        throw new Error(result.error);
      }

      if (!result.nModified) {
        if (onError) {
          onError(result);
        } else {
          throw new Error(`Unable to reset workunit ${vId}`);
        }

        return;
      }

      let wu;
      try {
        wu = await window.sch.get(`/api/df/workunits/detail?vId=${vId}`);
      } catch (error) {
        // Refresh the page to get the latest workunit
        window.location.reload();
        return;
      }

      dispatch({
        type: actionTypes.RESET_WORKUNIT_SUCCESS,
        data: wu.data,
      });

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      dispatch({
        type: actionTypes.RESET_WORKUNIT_ERROR,
        error,
      });
    }
  };
}

export function toggleWorkunitCancel_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.TOGGLE_WORKUNIT_CANCEL_PENDING });

    return window.sch
      .post('/api/df/workunits/toggle-cancel', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        if (result.success) {
          dispatch({
            type: actionTypes.TOGGLE_WORKUNIT_CANCEL_SUCCESS,
            data: result.workunit,
          });

          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          if (onError) {
            onError(result);
          }
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.TOGGLE_WORKUNIT_CANCEL_ERROR,
          error,
        });
      });
  };
}

export function unarchiveWorkunit_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UNARCHIVE_WORKUNIT_PENDING });

    return window.sch
      .post('/api/df/workunits/un-archive', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        if (result.success) {
          dispatch({
            type: actionTypes.UNARCHIVE_WORKUNIT_SUCCESS,
            data: {},
          });

          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          if (onError) {
            onError(result);
          }
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.UNARCHIVE_WORKUNIT_ERROR,
          error,
        });
      });
  };
}

export function saveWorkunitState_ac(
  body,
  { onSuccess, onError } = {},
  overrideComplete = false
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SAVE_WORKUNIT_STATE_PENDING });

    return window.sch
      .post(
        overrideComplete
          ? '/api/df/workunits/force_complete'
          : '/api/df/workunits/save',
        body
      )
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.SAVE_WORKUNIT_STATE_SUCCESS,
          data: result.updates,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.SAVE_WORKUNIT_STATE_ERROR,
          error,
        });
      });
  };
}

export function fetchNoteTemplates_ac(params, { onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_NOTE_TEMPLATES_PENDING });
    return window.sch
      .get('/api/notes:hdms:gethdrs?' + serialize(params), {})
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_NOTE_TEMPLATES_SUCCESS,
          data: result,
        });
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_NOTE_TEMPLATES_ERROR,
          error,
        });
      });
  };
}

export function addWorkunitNote_ac(params, { onSuccess, onError }) {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADD_WORKUNIT_NOTE_PENDING });

    try {
      const dfRes = await window.sch.post(
        '/api/df/workunits/notes/add',
        params
      );

      dispatch({
        type: actionTypes.ADD_WORKUNIT_NOTE_SUCCESS,
        data: dfRes.newNote,
      });

      if (onSuccess) {
        onSuccess(dfRes.hdmsError);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      dispatch({
        type: actionTypes.ADD_WORKUNIT_NOTE_ERROR,
        error,
      });
    }
  };
}

export function fetchWorkunitStatusSummary_ac(params = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_PENDING });

    return window.sch
      .post('/api/df/workunits/report', {
        kinds: ['overall'],
        ex_state: ['COMPLETE', 'CANCELLED'],
        ...params,
      })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_SUCCESS,
          data: result.overall_report,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_WORKUNITS_STATUS_SUMMARY_ERROR,
          error,
        });
      });
  };
}

export function fetchWorkunitPeriodReport_ac(params = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_PENDING });

    return window.sch
      .post('/api/df/workunits/period-report', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_SUCCESS,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_WORKUNIT_PERIOD_REPORTS_ERROR,
          error,
        });
      });
  };
}

export function saveWorkUnit_ac(data) {
  return {
    type: actionTypes.SAVE_WORKUNIT,
    data,
  };
}

export function setActiveDoc_ac(doc) {
  return {
    type: actionTypes.SET_ACTIVE_DOC,
    doc,
  };
}

export function setDocRotationPref_ac(docId, rotationPref) {
  return {
    type: actionTypes.SET_DOC_ROTATION_PREF,
    docId,
    rotationPref,
  };
}

export function setWorkunitProp_ac(key, value) {
  return {
    type: actionTypes.SET_WORKUNIT_PROP,
    key,
    value,
  };
}

export function setPhaseProp_ac(phase, prop, patch = true) {
  return {
    type: actionTypes.SET_PHASE_PROP,
    phase,
    prop,
    patch,
  };
}

export function setPhaseStatus_ac(phase, status) {
  return {
    type: actionTypes.SET_PHASE_STATUS,
    phase,
    status,
  };
}

export function setPhaseNote_ac(phase, note) {
  return {
    type: actionTypes.SET_PHASE_NOTE,
    phase,
    note,
  };
}

export function setCheckboxKeyValue_ac(phase, checkbox, key, value, product) {
  return {
    type: actionTypes.SET_CHECKBOX_KEY_VALUE,
    phase,
    checkbox,
    key,
    value,
    product,
  };
}

export function setProductKeyValue_ac(phase, product, key, value) {
  return {
    type: actionTypes.SET_PRODUCT_KEY_VALUE,
    phase,
    product,
    key,
    value,
  };
}

export function updatePhaseProduct_ac(phase, product) {
  return {
    type: actionTypes.UPDATE_PHASE_PRODUCT,
    phase,
    product,
  };
}

export function setHdmsWorkunitIDs_ac(numbers) {
  return {
    type: actionTypes.SET_HDMS_WORKUNIT_IDS,
    numbers,
  };
}

export function updatePhaseExtendedSLA_ac(phase, sla) {
  return {
    type: actionTypes.UPDATE_PHASE_EXTENDED_SLA,
    phase,
    sla,
  };
}

export function refactorWorkunitState_ac(newState) {
  return {
    type: actionTypes.REFACTOR_WORKUNIT_STATE,
    newState,
  };
}

export function setStateItem_ac(key, value) {
  return {
    type: actionTypes.SET_STATE_ITEM,
    key,
    value,
  };
}

export function setViewProps_ac(prop, keyOrObj, value, patch = true) {
  const act = {
    type: actionTypes.SET_VIEW_PROPS,
    prop,
    patch,
  };

  if (typeof keyOrObj === 'object') {
    act.value = keyOrObj;
  } else {
    act.key = keyOrObj;
    act.value = value;
  }

  return act;
}

export function flushState_ac() {
  return {
    type: actionTypes.FLUSH_STATE,
  };
}

export function stageSLADeadlines_ac(phase, unset = false, saveMode = true) {
  return {
    type: actionTypes.STAGE_SLA_DEADLINES,
    phase,
    unset,
    saveMode,
  };
}
