import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  withStyles,
  Select,
  Chip,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Loading } from '../../HourlyWos/components';
import { open_snack_ac } from '../../../actions/snack.ac';
// import bcrypt from 'bcryptjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const GET_PALCES = '/api/dt/places';
const UPDATE_DRIVER_DETAIL = '/api/dt/update_driver';
const GET_DRIVER_DETAIL_BY_ID = '/api/dt/drivers';

const styles = () => ({
  form: {
    width: '100%',
    marginBottom: 10,
  },
  submitButton: {
    marginTop: 10,
  },

  label: {
    position: 'absolute',
    padding: '18.5px 14px',
    fontSize: 14,
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    position: 'absolute',
    top: 0,
    left: 20,
    zIndex: 1,
    background: 'white',
    width: 60,
    textAlign: 'center',
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = [
  'admin',
  'inactive',
  'dispatcher',
  'oncall',
  'courier',
  'no_phone',
  'driver',
];

const EditDriverForm = (props) => {
  const { classes } = props;

  const [dc, setDc] = useState([]);
  const [formData, setFormData] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [driverDetail, setDriverDetail] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const driverId = props.location.pathname.split('/').pop();

  useEffect(() => {
    setFormData(driverDetail);
  }, [driverDetail]);

  async function getDc() {
    const data = await window.sch.get(GET_PALCES);
    return data;
  }

  async function getDriverDetailById(driverId) {
    const data = await window.sch.get(`${GET_DRIVER_DETAIL_BY_ID}/${driverId}`);
    return data;
  }

  async function updateDriverDetail(formData) {
    const data = await window.sch.post(UPDATE_DRIVER_DETAIL, {
      driver: formData,
    });
    return data;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'roles') {
      setMenuOpen(false);
    }

    // Email validation
    if (name === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      setEmailError(isValidEmail ? '' : 'Invalid email address');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const fetchDc = async () => {
    try {
      const { place } = await getDc();
      setDc(place);
    } catch (err) {
      props.open_snack_ac({
        variant: 'error',
        message: 'Error fetcting locations',
      });
    }
  };

  const fetchDriverDetailById = async () => {
    try {
      setIsLoading(true);
      const { driver } = await getDriverDetailById(driverId);
      setDriverDetail(driver);
    } catch (err) {
      setIsLoading(false);

      props.open_snack_ac({
        variant: 'error',
        message: 'Error fetcting driver details',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDriverDetailById();
    fetchDc();
    // eslint-disable-next-line
  }, []);
  const submitDriverDetail = async () => {
    try {
      setIsSubmitting(true);
      await updateDriverDetail(formData);

      props.open_snack_ac({
        variant: 'success',
        message: `${formData.driver_nm} detail updated sucessfully`,
      });
      props.history.push('/admin/dtrack/drivers');
    } catch (err) {
      setIsSubmitting(false);
      props.open_snack_ac({
        variant: 'error',
        message: 'Error updating details',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = (role) => {
    const newRoles = formData.roles.filter((r) => r !== role);

    setFormData({
      ...formData,
      roles: newRoles,
    });
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={8}>
        <Typography variant="h2" gutterBottom>
          Edit Driver
        </Typography>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    disabled
                    label="Driver’s HDMS Op ID"
                    name="driver_id"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_id || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Phone Model"
                        name="phone_model"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.phone_model || ''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Phone OS"
                        name="phone_os"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.phone_os || ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="ID"
                        name="_id"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData._id || ''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="UID"
                        name="u_id"
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.u_id || ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="driver_nm"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_nm || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    variant="outlined"
                    error={!!emailError}
                    onChange={handleChange}
                    helperText={emailError}
                    value={formData.email || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    name="ph"
                    label="Phone"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.ph || ''}
                  />
                </Grid>

                <Grid item xs={8} style={{ position: 'relative' }}>
                  <InputLabel
                    htmlFor="select-multiple-chip"
                    className={
                      formData.dc_id ? classes.labelShrink : classes.label
                    }>
                    Location
                  </InputLabel>

                  <Select
                    fullWidth
                    name="dc_id"
                    value={formData.dc_id || ''}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="select-multiple-chip"
                      />
                    }>
                    {dc.map((d) => {
                      return (
                        <MenuItem key={d._id} value={d.dc_id}>
                          {d.dc_nm}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={8} style={{ position: 'relative' }}>
                  <InputLabel
                    htmlFor="select-multiple-chip"
                    className={
                      formData.roles?.length
                        ? classes.labelShrink
                        : classes.label
                    }>
                    Roles
                  </InputLabel>

                  <Select
                    fullWidth
                    multiple
                    name="roles"
                    onChange={handleChange}
                    value={formData.roles || []}
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="select-multiple-chip"
                      />
                    }
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                            onDelete={() => handleDelete(value)}
                          />
                        ))}
                      </div>
                    )}
                    open={menuOpen}
                    MenuProps={MenuProps}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}>
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Change Password
                  </Button>
                </Grid> */}

                <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <Typography variant="h5" gutterBottom>
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    disabled
                    label="Old Password"
                    name="o_pw"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_id || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    disabled
                    label="New Password"
                    name="n_pw"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.driver_id || ''}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}>
                    Change Password
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={submitDriverDetail}
                    style={{ marginLeft: 10 }}>
                    Discard
                  </Button>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </form>
      )}
    </Grid>
  );
};

const mapDispatchToProps = {
  open_snack_ac,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(EditDriverForm));
